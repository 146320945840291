/**
 * See https://codeworkshop.dev/blog/2020-04-03-adding-orbit-controls-to-react-three-fiber/
 */

import React, { useEffect, useRef } from 'react';
import type { ReactThreeFiber } from '@react-three/fiber';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';

import type { ICameraControls } from './CameraControls.types';
import { TrackballControls } from './CustomTrackballControls';

export type { TrackballControls, ICameraControls };

export type CameraControlsProps = ReactThreeFiber.Overwrite<
    ReactThreeFiber.Object3DNode<TrackballControls, typeof TrackballControls>,
    {
        cameraControlsRef: React.MutableRefObject<TrackballControls | null>;
        onChange: (event: THREE.Event) => void;
        noRotate: boolean;
        rotateSpeed: number;
    }
>;

export const CameraControls = React.forwardRef<ICameraControls, CameraControlsProps>((props, ref) => {
    // Get a reference to the Three.js Camera, and the canvas html element.
    // We need these to setup the TrackballControls component.
    // https://threejs.org/docs/#examples/en/controls/TrackballControls
    const { invalidate, camera, set, get, viewport, events, gl } = useThree();

    const explDomElement = (events.connected || gl.domElement) as HTMLElement;

    const controls = React.useMemo(() => new TrackballControls(camera as THREE.OrthographicCamera, false), [camera]);

    // Make the camera controls known to the system
    useEffect(() => {
        if (props.cameraControlsRef) props.cameraControlsRef.current = controls;
    });

    useFrame(() => {
        if (controls.enabled) {
            controls.update();
        }
    }, -1);

    React.useEffect(() => {
        controls.connect(explDomElement);
        return () => void controls.dispose();
    }, [explDomElement, controls, invalidate]);

    useEffect(() => {
        const callback = (e: THREE.Event) => {
            invalidate();
            if (props.onChange) {
                props.onChange(e);
            }
        };
        controls.addEventListener('change' as never, callback);
        return () => {
            controls.removeEventListener('change' as never, callback);
        };
    }, [props.onChange, controls, invalidate]);

    React.useEffect(() => {
        controls.handleResize();
    }, [viewport, controls]);

    React.useEffect(() => {
        const old = get().controls;
        set({ controls });
        return () => set({ controls: old });
    }, [controls, get, set]);

    return <primitive ref={ref} object={controls} {...props} maxDistance={1.5} />;
});

export default CameraControls;
