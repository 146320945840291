import { useThree } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import * as THREE from 'three';
import React from 'react';

interface CameraRigProps {
    cameraRef: React.MutableRefObject<THREE.OrthographicCamera | null>;
    fov: number;
    position: [number, number, number];
}

// https://github.com/pmndrs/react-three-fiber/blob/master/markdown/recipes.md
export default function CameraRig(props: CameraRigProps) {
    const ref = useRef<THREE.OrthographicCamera>(null);
    const set = useThree(state => state.set);
    const { size } = useThree();
    // Make the camera known to the system
    useEffect(() => void set({ camera: ref.current! }), [set]);

    useEffect(() => {
        if (props.cameraRef) props.cameraRef.current = ref.current;
    });
    return (
        <orthographicCamera
            ref={ref}
            near={0}
            far={2000}
            zoom={6}
            top={size.height / 2}
            bottom={-size.height / 2}
            left={-size.width / 2}
            right={size.width / 2}
            {...props}
        />
    );
}
