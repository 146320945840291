import React, { useEffect } from 'react';
import type { TrackballControls } from '../CameraControls';

interface CameraOptionsProps {
    cameraControlsRef: React.MutableRefObject<TrackballControls | null>;
}

export default function CameraOptions({ cameraControlsRef }: CameraOptionsProps) {
    const resetView = () => {
        const controls = cameraControlsRef.current;
        if (controls) {
            controls.reset();
        }
    };

    useEffect(() => {
        resetView();
    }, [cameraControlsRef.current]);

    return (
        <>
            <label>Camera view</label>
            <button onClick={resetView}>Reset</button>
        </>
    );
}
