import { useCallback, useEffect } from 'react';

function useKeyboardShortcuts(
    keyCodes: string[],
    onPress: (code: string, e: KeyboardEvent) => void,
    eventName: 'keyup' | 'keydown' = 'keyup'
) {
    // useCallback prevents function from being redefined every time component is rendered
    const memoizedKeyUpListener = useCallback(
        (e: KeyboardEvent) => {
            const { code } = e;
            if (!keyCodes.includes(code)) return;
            onPress(code, e);
        },
        [keyCodes, onPress]
    );

    useEffect(() => {
        window.addEventListener(eventName, memoizedKeyUpListener, true);
        return () => window.removeEventListener(eventName, memoizedKeyUpListener, true);
    }, [memoizedKeyUpListener]);
}

export default useKeyboardShortcuts;
