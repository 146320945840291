import React, { useEffect, useState } from 'react';

import { DandyPLYLoader } from './DandyPLYLoader';
import { DandyLabelLoader } from './DandyLabelLoader';
import { DandyLabelExporter } from './DandyLabelExporter';
import options from '../config.json';
import { BufferAttribute, Color } from 'three';
import { VertexFaceIndex } from './VertexFaceIndex';
import ProgressBar from '../ProgressBar';
import useDocumentTitle from '../useDocumentTitle';
import useBeforeUnload from '../useBeforeUnload';
import { BackendApi } from '../Api/BackendApi';
import styles from './styles.module.css';
import { AnnotationData, ReactSetter, UserInfo } from '../types';
import CloudBucket from '../CloudBucket';
import * as THREE from 'three';

interface PlyFile {
    name: string;
    url: string;
}

interface ImportExportProps {
    userInfo: UserInfo | null;
    setUserInfo: ReactSetter<UserInfo | null>;
    loadedAnnotationData: AnnotationData | undefined;
    setLoadedAnnotationData: ReactSetter<AnnotationData | undefined>;
    annotationData: AnnotationData | undefined;
    setAnnotationData: ReactSetter<AnnotationData | undefined>;
    mesh: React.MutableRefObject<THREE.Mesh | null>;
    geometry: THREE.BufferGeometry | null;
    setGeometry: ReactSetter<THREE.BufferGeometry | null>;
    setFaceIndex: ReactSetter<VertexFaceIndex | null>;
    bucket: CloudBucket;
    isDirty: boolean;
    setIsDirty: ReactSetter<boolean>;
}

function ImportExport({
    userInfo,
    setUserInfo,
    loadedAnnotationData,
    setLoadedAnnotationData,
    annotationData,
    setAnnotationData,
    mesh,
    geometry,
    setGeometry,
    setFaceIndex,
    bucket,
    isDirty,
    setIsDirty,
}: ImportExportProps) {
    const [plyFile, setPlyFile] = useState<PlyFile | null>(null);
    const [message, setMessage] = useState('');

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [backendApi] = useState(new BackendApi());

    const title = plyFile ? `${isDirty ? '*' : ''}${plyFile.name} - Mesh Labeler` : 'Mesh Labeler';
    useDocumentTitle(title);
    useBeforeUnload(isDirty); // warn before navigating if dirty

    const triggerLogin = () => {
        if (!bucket) return;
        setMessage('Authenticating...');

        backendApi
            .fetchUserInfo()
            .then(userInfo => {
                setUserInfo(userInfo);
                if (userInfo.userEmail) {
                    setMessage(`Successfully logged in`);
                } else {
                    setMessage(`Not logged in`);
                }
            })
            .catch(e => setMessage(e.message))
            .finally(() => setLoading(false));
    };

    const triggerLoginAndDownload = (uuid: string) => {
        if (!bucket) return;

        // Load the file, call setPlyFile on it
        setLoading(true);
        setMessage('Authenticating...');

        backendApi
            .fetchUserInfo()
            .then(userInfo => {
                setUserInfo(userInfo);
                if (!userInfo.userEmail) {
                    setMessage('Not logged in');
                    throw new Error(`Not logged in`);
                }
                setMessage('Downloading labels...');
                return backendApi.makeDownloadUrlsRequest(uuid);
            })
            .then(async ({ labels_url, scan_url }) => {
                const labelsJson = await (await fetch(labels_url)).json();
                const labelLoader = new DandyLabelLoader(setLoadedAnnotationData, setAnnotationData);
                labelLoader.load(labelsJson);
                setMessage('');
                setPlyFile({ url: scan_url, name: 'PLY File' });
            })
            .catch(e => setMessage(e.message))
            .finally(() => setLoading(false));
    };

    const location = window.location;

    const url = new URL(location.href);
    const itemUuid = url.pathname.startsWith('/label/') ? url.pathname.slice('/label/'.length) : undefined;

    useEffect(() => {
        if (!bucket) return;

        const timeout = setTimeout(() => {
            if (itemUuid) {
                triggerLoginAndDownload(itemUuid);
            } else {
                triggerLogin();
            }
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, [itemUuid, bucket]);

    useEffect(() => {
        if (plyFile) {
            // Disable texcoord loading, otherwise PLYLoader will convert
            // to unindexed geometry
            const loader = new DandyPLYLoader();
            loader.setPropertyNameMapping({ texcoord: 'null' });
            loader.load(plyFile.url, (geometry: THREE.BufferGeometry) => {
                // translate to make it centered
                geometry.computeBoundingBox();
                const box = geometry.boundingBox;
                if (box) {
                    geometry.translate(
                        -(box.min.x + box.max.x) / 2,
                        -(box.min.y + box.max.y) / 2,
                        -(box.min.z + box.max.z) / 2
                    );
                }

                if (!geometry.hasAttribute('normal')) {
                    geometry.computeVertexNormals();
                }

                const colorAttr = geometry.getAttribute('color');
                if (!geometry.hasAttribute('original_color') && colorAttr) {
                    const colorAttributeCopy = colorAttr.clone();
                    geometry.setAttribute('original_color', colorAttributeCopy);
                }

                options.groups.forEach(groupId => {
                    const attrName = `${groupId}_mask`;

                    if (!geometry.hasAttribute(attrName)) {
                        const vertexCount = geometry.attributes.position!.count;
                        const attrArray = new Uint8Array(vertexCount);
                        const maskAttr = new BufferAttribute(attrArray, 1);
                        geometry.setAttribute(attrName, maskAttr);
                    } else {
                        console.log(`${attrName} group loaded`);
                    }
                });

                const index = new VertexFaceIndex(geometry);
                index.build();

                setFaceIndex(index);

                setGeometry(geometry);
            });
        }
    }, [plyFile, setGeometry, setFaceIndex]);

    useEffect(() => {
        if (!geometry || !loadedAnnotationData) {
            return;
        }

        const colorAttr = geometry.getAttribute('color');
        const labelColorMap: Record<string, string> = {};
        options.labels.forEach(({ group, id, color }) => {
            labelColorMap[`${group}-${id}`] = color;
        });
        options.groups.forEach(groupId => {
            const attrName = `${groupId}_mask`;
            const maskAttr = geometry.getAttribute(attrName);
            (loadedAnnotationData.vertexMasks?.[groupId] || []).forEach((labelId: number, idx: number) => {
                maskAttr.setX(idx, labelId);
                const labelColor = labelColorMap[`${groupId}-${labelId}`];
                if (!labelColor) return;
                const color = new Color(labelColor);
                colorAttr.setXYZ(idx, color.r, color.g, color.b);
            });
        });
        colorAttr.needsUpdate = true;
    }, [loadedAnnotationData, geometry]);

    const getDownloadData = () => {
        const exporter = new DandyLabelExporter();
        if (!mesh.current) {
            throw new Error('No mesh.current');
        }

        // Download the file
        const data = exporter.parse(userInfo!, annotationData, options.groups, mesh.current);

        return data;
    };

    // If a critical error happens, make the user very aware of it.
    function displayError(context: string, e: unknown) {
        let msg;
        if (e instanceof Error) {
            msg = JSON.stringify(e.toString());
        } else {
            msg = JSON.stringify(e, null, 1);
        }

        msg = 'An Error occurred during ' + context + ': ' + msg;
        setMessage(msg);
        alert(msg);
    }

    return (
        <>
            {plyFile && itemUuid && (
                <>
                    <title>{plyFile.name} - Mesh Labeler</title>
                    <div className={styles.filenameDisplay}>
                        <span>{plyFile.name}</span>
                    </div>
                    <div>
                        <button
                            disabled={loading || !isDirty}
                            onClick={() => {
                                setLoading(true);
                                setMessage('Uploading...');
                                setTimeout(() => {
                                    const description = 'Export to Google Bucket';
                                    try {
                                        const fileData = getDownloadData();
                                        bucket
                                            .uploadLabels(itemUuid, fileData, setProgress)
                                            .then(() => {
                                                return backendApi.makeMarkSubmissionRequest(itemUuid);
                                            })
                                            .then(() => {
                                                setMessage(description + ' Successful!');
                                                setProgress(0);
                                                setIsDirty(false);
                                            })
                                            .catch(e => displayError(description, e))
                                            .finally(() => setLoading(false));
                                    } catch (e) {
                                        // an error occured while preparing the data to upload
                                        displayError(description, e);
                                    }
                                }, 100);
                            }}
                        >
                            Export to Google Bucket
                        </button>
                    </div>
                </>
            )}
            {message && (
                <div className={styles.bucketMessage}>
                    <p>{message}</p>
                </div>
            )}
            {loading && <ProgressBar progress={progress} />}
        </>
    );
}

export default ImportExport;
