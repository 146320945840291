import { ThreeEvent } from '@react-three/fiber';

// greatly speed up raycasting for mouse movement on mesh.
// see https://github.com/pmndrs/react-three-fiber/issues/320#issuecomment-879772689
import { Bvh } from '@react-three/drei';
import { CENTER } from 'three-mesh-bvh';

import React, { useRef, useState, useEffect } from 'react';
import { mergeGroups } from 'three/examples/jsm/utils/BufferGeometryUtils';

import * as THREE from 'three';
import type { ICrossSectionOptions } from '../Controls/CrossSectionOptions';
import { VertexFaceIndex } from '../ImportExport/VertexFaceIndex';
import { ReactSetter } from '../types';

export const PointerIntersectionContext = React.createContext<Intersection | null>(null);
export const MeshContext = React.createContext<{ mesh: THREE.Mesh | null }>({ mesh: null });
export const VISIBLE_MATERIAL_INDEX = 0;
export const HIDDEN_MATERIAL_INDEX = 1;

interface Intersection {
    face: THREE.Face | null | undefined;
    faceIndex: number | null | undefined;
    point: THREE.Vector3;
    distance: number;
    mouse: {
        clientX: number;
        clientY: number;
        x: number;
        y: number;
        shiftKey: boolean;
        metaKey: boolean;
        ctrlKey: boolean;
        altKey: boolean;
        buttons: number;
    };
}

interface DandyMeshProps {
    meshRef: React.MutableRefObject<THREE.Mesh | null>;
    geometry: THREE.BufferGeometry | null;
    children: React.ReactNode;
    onPointerOver: (event: ThreeEvent<PointerEvent>) => void;
    onPointerOut: (event: ThreeEvent<PointerEvent>) => void;
    crossSectionOptions: ICrossSectionOptions;
    cameraRef: React.MutableRefObject<THREE.OrthographicCamera | null>;
    faceIndex: VertexFaceIndex | null;
    setFaceIndex: ReactSetter<VertexFaceIndex | null>;
    doubleSided: boolean;
}

function DandyMesh({
    meshRef,
    geometry,
    children,
    onPointerOver,
    onPointerOut,
    crossSectionOptions,
    cameraRef,
    faceIndex,
    setFaceIndex,
    doubleSided,
}: DandyMeshProps) {
    const [intersection, setIntersection] = useState<Intersection | null>(null);
    const handlePointerUpdate = (event: ThreeEvent<PointerEvent>) => {
        const { clientX, clientY, x, y, shiftKey, metaKey, ctrlKey, altKey, buttons } = event;
        setIntersection({
            face: event.face,
            faceIndex: event.faceIndex,
            point: event.point,
            distance: event.distance,
            mouse: {
                clientX,
                clientY,
                x,
                y,
                shiftKey,
                metaKey,
                ctrlKey,
                altKey,
                buttons,
            },
        });
    };

    const handlePointerOut = (event: ThreeEvent<PointerEvent>) => {
        setIntersection(null);
        onPointerOut && onPointerOut(event);
    };

    const resetMeshDrawRangeAndBvh = (mesh: THREE.Mesh) => {
        // explicitly avoid drawing fully transparent parts of geometry
        if (!crossSectionOptions.isDrawn && mesh.geometry.groups[0]) {
            mesh.geometry.setDrawRange(mesh.geometry.groups[0].start, mesh.geometry.groups[0].count);
        } else {
            mesh.geometry.setDrawRange(0, Infinity);
        }

        // explicitly recompute our Bvh data structure with changes, this is not automatic
        mesh.geometry.disposeBoundsTree();
        mesh.geometry.computeBoundsTree({
            strategy: CENTER,
            indirect: true, // this experimental feature isn't in type defs
        } as any); // hence, cast as any here
    };

    useEffect(() => {
        if (!meshRef?.current?.material || !meshRef?.current?.geometry?.index) return;
        if (!cameraRef?.current?.position) return;
        if (!faceIndex) return;
        meshRef.current.geometry.clearGroups();
        const pos = new THREE.Vector3();
        const dir = new THREE.Vector3();
        cameraRef.current.getWorldDirection(dir);
        // find every face within the cross-section visible region.
        // all vertices of a face must have the same group/material
        for (let i = 0; i < meshRef.current.geometry.index.count; i += 3) {
            let visible = false;
            for (let j = 0; j < 3; j++) {
                const vi = meshRef.current.geometry.index.getX(i + j);
                [pos.x, pos.y, pos.z] = faceIndex.getVertexPosition(vi);
                if (pos.sub(cameraRef.current.position).dot(dir) >= crossSectionOptions.distance) {
                    visible = true;
                    break;
                }
            }
            for (let j = 0; j < 3; j++) {
                meshRef.current.geometry.addGroup(i + j, 1, visible ? 0 : 1);
            }
        }

        // merge groups to compact them for faster rendering.
        // this will re-order the mesh's face vertex index, which is why we rebuild our index over it
        mergeGroups(meshRef.current.geometry);

        // rebuild the vertex face index with the re-ordered faces
        const index = new VertexFaceIndex(meshRef.current.geometry);
        index.build();
        setFaceIndex(index);

        // refresh mesh draw range and bvh
        resetMeshDrawRangeAndBvh(meshRef.current);
    }, [crossSectionOptions.distance, meshRef, cameraRef, geometry]);

    useEffect(() => {
        if (!meshRef?.current?.geometry?.index) return;

        // refresh mesh draw range and bvh
        resetMeshDrawRangeAndBvh(meshRef.current);
    }, [crossSectionOptions.isDrawn]);

    const group = useRef<THREE.Group>(null);

    return (
        geometry && (
            <group ref={group}>
                <PointerIntersectionContext.Provider value={intersection}>
                    <MeshContext.Provider value={{ mesh: meshRef.current }}>
                        <Bvh strategy={CENTER} indirect={true}>
                            <mesh
                                ref={meshRef}
                                onPointerOver={onPointerOver}
                                onPointerOut={handlePointerOut}
                                onPointerUp={handlePointerUpdate}
                                onPointerDown={handlePointerUpdate}
                                onPointerMove={handlePointerUpdate}
                            >
                                <bufferGeometry attach="geometry" {...geometry} />
                                <meshPhongMaterial
                                    attach="material-0"
                                    transparent={false}
                                    opacity={1}
                                    vertexColors={true}
                                    side={doubleSided ? THREE.DoubleSide : THREE.FrontSide}
                                />
                                <meshPhongMaterial
                                    attach="material-1"
                                    transparent={true}
                                    opacity={crossSectionOptions.opacity / 100}
                                    vertexColors={true}
                                    side={doubleSided ? THREE.DoubleSide : THREE.FrontSide}
                                />
                            </mesh>
                        </Bvh>
                        {children}
                    </MeshContext.Provider>
                </PointerIntersectionContext.Provider>
            </group>
        )
    );
}

export default DandyMesh;
