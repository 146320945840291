import React from 'react';

import './text-checkbox.css';

interface TextCheckboxProps {
    checked: boolean;
    onChange?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    className?: string;
    text: string;
}

export default function TextCheckbox({ checked, onChange, className, text, ...rest }: TextCheckboxProps) {
    className = className ? `text-checkbox ${className}` : 'text-checkbox';
    if (checked) {
        className += ' checked';
    }

    function handleClick(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
        event.stopPropagation();
        onChange && onChange(event);
    }

    return (
        <span className={className} onClick={handleClick} {...rest}>
            {text}
        </span>
    );
}
