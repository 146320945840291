import { BackendApi } from '../Api/BackendApi';
import { ReactSetter } from '../types';

function makeResponseSummary(status: number, url: string, xhr: XMLHttpRequest) {
    let response: Record<string, unknown> = {};
    let json_parsed = false;
    const response_text_type = xhr.responseType === 'json' || xhr.responseType === '' || xhr.responseType === 'text';

    if (response_text_type && xhr.responseText) {
        try {
            response = JSON.parse(xhr.responseText);
            json_parsed = true;
        } catch (e) {
            console.log('error parsing response text:', e);
        }
    }
    response['status'] = status;
    response['statusText'] = xhr.statusText;
    response['url'] = url;
    response['responseType'] = xhr.responseType;
    if (response_text_type && !json_parsed && xhr.responseText) {
        response['responseText'] = xhr.responseText;
    }

    return response;
}

class CloudBucket {
    private readonly backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;
    private readonly backendApi = new BackendApi();

    async uploadLabels(itemUuid: string, labelJson: string, progressCallback: ReactSetter<number> = () => {}) {
        const { label_upload_url } = await this.backendApi.makeLabelUploadUrlRequest(itemUuid);
        const upload_request = await this.makeUploadViaUrlRequest(
            labelJson,
            'application/json',
            label_upload_url,
            progressCallback
        );
        return upload_request;
    }

    makeUploadViaUrlRequest(
        object: string,
        contentType: string,
        signedUrl: string,
        progressCallback: (progress: number) => void
    ) {
        return new Promise<void>(function (resolve, reject) {
            const xhr = new XMLHttpRequest();
            xhr.upload.onprogress = function (event) {
                progressCallback(event.loaded / event.total);
            };
            xhr.onload = function () {
                if (this.status === 200) {
                    resolve();
                } else {
                    reject(makeResponseSummary(this.status, signedUrl, xhr));
                }
            };
            xhr.onerror = function () {
                reject({
                    status: this.status,
                    statusText: xhr.statusText,
                    url: signedUrl,
                });
            };
            xhr.open('PUT', signedUrl);
            xhr.setRequestHeader('Content-Type', contentType);
            xhr.send(object);
        });
    }
}

export default CloudBucket;
