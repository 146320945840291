import { Label } from '../types';
import * as THREE from 'three';

export default class MaskVerticesCache {
    readonly vertsByLabel: Record<string, Set<number>> = {};

    constructor(private labels: Label[]) {
        labels.forEach(l => {
            this.vertsByLabel[l.label] = new Set(); // indexed on label name
        });
    }

    reset(geometry: THREE.BufferGeometry) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        geometry &&
            this.labels.forEach(l => {
                // This might happen when we are loading new mesh
                this.vertsByLabel[l.label]?.clear();

                const maskAttr = geometry.getAttribute(`${l.group}_mask`);
                if (!maskAttr) return;

                const mask = maskAttr.array;
                for (let i = 0; i < mask.length; i++) {
                    if (mask[i] === l.id) {
                        this.vertsByLabel[l.label]?.add(i);
                    }
                }
            });
    }

    listAll(): Set<number> {
        const result = new Set<number>();
        this.labels.forEach(l => {
            this.vertsByLabel[l.label]?.forEach(v => {
                result.add(v);
            });
        });
        return result;
    }

    labelName(groupId: string, labelId: number): string | undefined {
        const entry = this.labels.find(l => l.group === groupId && l.id === labelId);
        return entry?.label;
    }

    getVertsByLabel(groupId: string, labelId: number): Set<number> {
        const labelName = this.labelName(groupId, labelId);
        return (labelName && this.vertsByLabel[labelName]) || new Set();
    }

    mark(groupId: string, labelId: number, vertices: number[]) {
        const labelName = this.labelName(groupId, labelId);
        if (labelName) {
            vertices.forEach(v => this.vertsByLabel[labelName]?.add(v));
        }
    }

    clear(groupId: string, labelId: number, vertices: number[]) {
        const labelName = this.labelName(groupId, labelId);
        if (labelName) {
            vertices.forEach(v => this.vertsByLabel[labelName]?.delete(v));
        }
    }
}
