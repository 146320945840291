import { AnnotationData, ReactSetter } from '../types';

class DandyLabelLoader {
    constructor(
        private readonly setLoadedAnnotationData: ReactSetter<AnnotationData | undefined>,
        private readonly setAnnotationData: ReactSetter<AnnotationData | undefined>
    ) {}

    load(data: AnnotationData, onLoad?: (data: AnnotationData) => void) {
        console.log('loading label data', data);
        // this triggers the update of the mesh once geometry is also available
        this.setLoadedAnnotationData(data || {});
        // this is the state we store for writing labels
        this.setAnnotationData(data || {});
        onLoad?.(data);
    }
}

export { DandyLabelLoader };
