import React from 'react';
import options from '../config.json';
import { AnnotationData, MetaAttribute, ReactSetter, UserInfo } from '../types';

import styles from './styles.module.css';

interface LabelMetadataProps {
    userInfo: UserInfo | null;
    annotationData: AnnotationData | undefined;
    setAnnotationData: ReactSetter<AnnotationData | undefined>;
    saveSnapshot: () => void;
}
function LabelMetadata({ userInfo, annotationData, setAnnotationData, saveSnapshot }: LabelMetadataProps) {
    // filter labels to those in the specific jaw
    const jaw = annotationData?.jaw;

    const renderControl = (metaAttribute: MetaAttribute) => {
        if (metaAttribute.type === 'checkbox') {
            const metaAttributes = annotationData?.metaAttributes || {};
            const checked = !!metaAttributes[metaAttribute.id];
            return (
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={() => {
                        saveSnapshot();
                        setAnnotationData({
                            ...annotationData,
                            metaAttributes: {
                                ...metaAttributes,
                                [metaAttribute.id]: !checked,
                            },
                        });
                    }}
                />
            );
        }
        return null;
    };

    return (
        <div className={styles.metadata}>
            <div>
                <span>
                    {userInfo && userInfo.userEmail ? (
                        <button
                            onClick={() => {
                                window.location.assign('/auth/logout');
                            }}
                        >
                            Logout
                        </button>
                    ) : (
                        <button
                            onClick={() => {
                                window.location.assign('/auth/login');
                            }}
                        >
                            Login
                        </button>
                    )}
                </span>
            </div>
            <div>
                <span>User:</span>
                <span>{userInfo?.userEmail || ''}</span>
            </div>
            <div>
                <span>Jaw:</span>
                <span>{jaw}</span>
            </div>
            {options.metaAttributes.map((metaAttribute, idx) => {
                return (
                    <div key={idx}>
                        <span>{metaAttribute.label}:</span>
                        <span>{renderControl(metaAttribute)}</span>
                    </div>
                );
            })}
        </div>
    );
}

export default LabelMetadata;
