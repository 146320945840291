import * as THREE from 'three';
import type { AnnotationData, UserInfo } from '../types';

// Increment this when format changes significantly
const VERSION = 1;

class DandyLabelExporter {
    parse(userInfo: UserInfo, annotationData: AnnotationData | undefined, groupNames: string[], object: THREE.Mesh) {
        // Iterate over the valid meshes in the object
        function traverseMeshes(cb: (mesh: THREE.Mesh, geometry: THREE.BufferGeometry) => void) {
            object.traverse(function (child) {
                if (child instanceof THREE.Mesh) {
                    const mesh = child;
                    const geometry = mesh.geometry;

                    if (geometry.isBufferGeometry !== true) {
                        throw new Error('THREE.PLYExporter: Geometry is not of type THREE.BufferGeometry.');
                    }

                    if (geometry.hasAttribute('position') === true) {
                        cb(mesh, geometry);
                    }
                }
            });
        }

        const vertexMasks: Record<string, number[]> = {};
        groupNames.forEach(groupName => {
            vertexMasks[groupName] = [];
        });

        traverseMeshes(function (mesh, geometry) {
            const vertices = geometry.getAttribute('position');

            groupNames.forEach(groupName => {
                const maskAttr = geometry.getAttribute(groupName + '_mask');
                for (let i = 0, l = vertices.count; i < l; i++) {
                    let val = 0;
                    if (maskAttr) {
                        val = maskAttr.getX(i);
                    }
                    vertexMasks[groupName]!.push(val);
                }
            });
        });

        const result = {
            ...annotationData,
            version: VERSION,
            annotatorInfo: userInfo,
            vertexMasks,
        };

        return JSON.stringify(result);
    }
}

export { DandyLabelExporter };
