import React from 'react';
import { ReactSetter } from '../types';
import { markerSizeToMm } from '../Marker/marker-size-utils';

export interface ICrossSectionOptions {
    opacity: number; // opacity of hidden area
    distance: number; // distance from camera that is transparent / non-opaque
    isDrawn: boolean; // is cross-section drawn or not (we don't draw when opacity is zero)
}

interface CrossSectionOptionsProps {
    crossSectionOptions: ICrossSectionOptions;
    setCrossSectionOptions: ReactSetter<ICrossSectionOptions>;
}

export default function CrossSectionOptions({ crossSectionOptions, setCrossSectionOptions }: CrossSectionOptionsProps) {
    function handleDistanceChange(event: React.ChangeEvent<HTMLInputElement>) {
        const opt = {
            ...crossSectionOptions,
            distance: parseInt(event.target.value),
        };
        setCrossSectionOptions(opt);
    }
    function handleOpacityChange(event: React.ChangeEvent<HTMLInputElement>) {
        const opacity = parseInt(event.target.value);
        const opt = {
            ...crossSectionOptions,
            opacity,
            isDrawn: opacity > 0,
        };
        setCrossSectionOptions(opt);
    }

    return (
        <>
            <label>Cross-section distance: {markerSizeToMm(crossSectionOptions.distance).toFixed(1)}mm</label>
            <input
                id="cross-section"
                type="range"
                min="0"
                max="100"
                value={crossSectionOptions.distance}
                onChange={handleDistanceChange}
            ></input>
            <label>Cross-section opacity: {crossSectionOptions.opacity}%</label>
            <input
                id="cross-section"
                type="range"
                min="0"
                max="100"
                value={crossSectionOptions.opacity}
                onChange={handleOpacityChange}
            ></input>
        </>
    );
}
