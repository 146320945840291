import React from 'react';

import styles from './styles.module.css';

interface ShortcutIconProps {
    character: string;
}

function ShortcutIcon(props: ShortcutIconProps) {
    return <span className={styles.shortcutIcon}>{props.character}</span>;
}

export default ShortcutIcon;
