import * as THREE from 'three';
import { Position } from '../types';
import { HIDDEN_MATERIAL_INDEX, VISIBLE_MATERIAL_INDEX } from '../DandyMesh';

export class VertexFaceIndex {
    private readonly positions: THREE.BufferAttribute | THREE.InterleavedBufferAttribute;
    private readonly vertexIndex: THREE.BufferAttribute;
    private readonly groups: THREE.GeometryGroup[];
    private readonly data: Record<string, number[]> = {};
    private readonly hiddenVertices: Set<number> = new Set<number>();

    constructor(geometry: THREE.BufferGeometry) {
        this.positions = geometry.getAttribute('position');
        if (!geometry.index) {
            throw new Error('Geometry must be indexed.');
        }
        this.vertexIndex = geometry.index;
        this.groups = geometry.groups;
    }

    build() {
        for (let faceIndex = 0; faceIndex < this.vertexIndex.count / 3; faceIndex++) {
            const face = this.readFaceByFaceIndex(faceIndex);

            this.addVertexFacePair(face.a, faceIndex);
            this.addVertexFacePair(face.b, faceIndex);
            this.addVertexFacePair(face.c, faceIndex);
        }
        // a vertex is paintable if it does not appear at all in the hidden group
        this.hiddenVertices.clear();
        for (const group of this.groups) {
            if (group.materialIndex !== HIDDEN_MATERIAL_INDEX) {
                continue;
            }
            for (let i = group.start; i < group.start + group.count; i++) {
                const vi = this.getVertexIndex(i);
                this.hiddenVertices.add(vi);
            }
        }
    }

    getVertexIndex(vi: number) {
        return this.vertexIndex.getX(vi);
    }

    readFaceByFaceIndex(faceIndex: number): { a: Position; b: Position; c: Position } {
        const vertexIndex = this.getVertexIndex(faceIndex * 3);
        const vertexIndex1 = this.getVertexIndex(faceIndex * 3 + 1);
        const vertexIndex2 = this.getVertexIndex(faceIndex * 3 + 2);

        return {
            a: this.getVertexPosition(vertexIndex),
            b: this.getVertexPosition(vertexIndex1),
            c: this.getVertexPosition(vertexIndex2),
        };
    }

    addVertexFacePair(v: Position, faceIndex: number) {
        const vh = getVertexHash(v);
        if (!this.data[vh]) {
            this.data[vh] = [];
        }

        this.data[vh]!.push(faceIndex);
    }

    getFacesByVertexIndex(vi: number) {
        const v = this.getVertexPosition(vi);

        return this.data[getVertexHash(v)] || [];
    }

    getVertexPosition(vi: number): Position {
        return [this.positions.getX(vi), this.positions.getY(vi), this.positions.getZ(vi)];
    }

    intersectPaintableVertices(vertices: number[]): void {
        // modify vertices with filtered list
        vertices.splice(0, vertices.length, ...vertices.filter(vi => !this.hiddenVertices.has(vi)));
    }

    isPaintableFace(face: THREE.Face): boolean {
        // we can't use face.materialIndex any more as it is always zero
        // after implementing Bvh optimization
        //return face.materialIndex === VISIBLE_MATERIAL_INDEX;
        const hasHiddenVertex =
            this.hiddenVertices.has(face.a) || this.hiddenVertices.has(face.b) || this.hiddenVertices.has(face.c);
        return !hasHiddenVertex;
    }
}
const precision = Math.pow(10, 3);
function getVertexHash(v: Position) {
    return `${Math.ceil(v[0] * precision)}_${Math.ceil(v[1] * precision)}_${Math.ceil(v[2] * precision)}`;
}
