import { useState, useRef } from 'react';
import useKeyboardShortcuts from '../useKeyboardShortcuts';
import config from '../config.json';
import { maskVertices, restoreAllColorsByMasks } from '../Marker';
import { AnnotationData, Label, ReactSetter } from '../types';
import * as THREE from 'three';

const undoLimit = config.app.undoLimit;

interface UndoItem {
    groupMasks: THREE.BufferAttribute[];
    annotationData: AnnotationData;
}

function useOperationStack({
    geometry,
    orderedVisibleLabels,
    annotationData,
    setAnnotationData,
}: {
    geometry: THREE.BufferGeometry | null;
    orderedVisibleLabels: Label[];
    annotationData: AnnotationData | undefined;
    setAnnotationData: ReactSetter<AnnotationData | undefined>;
}) {
    const [undoStack, setUndoStack] = useState<UndoItem[]>([]);
    const [redoStack, setRedoStack] = useState<UndoItem[]>([]);
    const [isDirty, setIsDirty] = useState(false);
    const baseColorAttr = useRef<THREE.BufferAttribute | undefined>(undefined); // saturation-adjusted color

    useKeyboardShortcuts(
        ['KeyZ'],
        (code, e) => {
            if (!geometry || !annotationData) {
                return;
            }
            if (!e.ctrlKey && !e.metaKey) {
                return;
            }
            if (!e.shiftKey) {
                if (undoStack.length > 0) {
                    console.log('pop from undo stack');
                    // add to redo stack
                    const redo = {
                        groupMasks: config.groups.map(groupId => geometry.getAttribute(`${groupId}_mask`).clone()),
                        annotationData,
                    };
                    setRedoStack([...redoStack, redo]);

                    // now handle undo
                    const undo = undoStack.slice(-1)[0]!;
                    setUndoStack(undoStack.slice(0, -1));
                    config.groups.forEach((groupId, groupIdx) => {
                        geometry.setAttribute(`${groupId}_mask`, undo.groupMasks[groupIdx]!);
                    });
                    maskVertices.reset(geometry);
                    restoreAllColorsByMasks(geometry, orderedVisibleLabels, baseColorAttr.current);
                    setAnnotationData(undo.annotationData);
                    e.preventDefault(); // prevent from bubbling
                }
            } else {
                if (redoStack.length > 0) {
                    console.log('pop from redo stack');
                    // add to undo stack
                    const undo = {
                        groupMasks: config.groups.map(groupId => geometry.getAttribute(`${groupId}_mask`).clone()),
                        annotationData,
                    };
                    setUndoStack([...undoStack, undo]);

                    // now handle redo
                    const redo = redoStack.slice(-1)[0]!;
                    setRedoStack(redoStack.slice(0, -1));
                    config.groups.forEach((groupId, groupIdx) => {
                        geometry.setAttribute(`${groupId}_mask`, redo.groupMasks[groupIdx]!);
                    });
                    maskVertices.reset(geometry);
                    restoreAllColorsByMasks(geometry, orderedVisibleLabels, baseColorAttr.current);
                    setAnnotationData(redo.annotationData);
                    e.preventDefault(); // prevent from bubbling
                }
            }
        },
        'keydown'
    );

    const saveSnapshot = () => {
        if (!geometry || !annotationData) {
            return;
        }
        const undo = {
            groupMasks: config.groups.map(groupId => geometry.getAttribute(`${groupId}_mask`).clone()),
            annotationData,
        };
        setUndoStack([...(undoLimit === 1 ? [] : undoLimit ? undoStack.slice(-undoLimit + 1) : undoStack), undo]);
        setRedoStack([]);
        setIsDirty(true);
        console.log('push to undo stack');
    };

    return { saveSnapshot, isDirty, setIsDirty, baseColorAttr };
}

export default useOperationStack;
