import styles from './input-dial.module.css';
import React from 'react';
import { markerSizeToMm } from '../Marker/marker-size-utils';

interface InputLabelProps {
    name: string;
    value: number;
    min: number;
    max: number;
    onChange: (value: number) => void;
}
export default function InputDial({ name, value, min, max, onChange }: InputLabelProps) {
    return (
        <div className={styles.inputDial}>
            <label>
                {name}: {markerSizeToMm(value).toFixed(1)}mm
            </label>
            <button
                onClick={() => {
                    if (value === min) return;
                    onChange(value - 1);
                }}
            >
                −
            </button>
            <button
                onClick={() => {
                    if (value === max) return;
                    onChange(value + 1);
                }}
            >
                +
            </button>
            <input
                type={'range'}
                min={min}
                max={max}
                step={1}
                value={value}
                onChange={e => onChange(parseInt(e.target.value))}
            />
        </div>
    );
}
